/* app/ui/nav/load */

import $ from 'jquery';
import 'Lib/velocity.min';

import { MediaQueries } from 'Util/mediaqueries';
import * as Keybinding from 'Util/keybinding';
import { NavSmall } from 'App/nav/small';
import { NavLarge } from 'App/nav/large';
import { HeaderSticky } from 'App/nav/sticky';

import { publish, subscribe } from 'Util/pubsub';

var selectors = {
	header: '.js-header',
	notification: '.js-header-notification',
	nav: '.js-navigation',
	navItem: '.js-navigation__item',
	navItemMega: '.js-navigation__item--megamenu',
	megamenuHeading: '.js-megamenu__heading',
};
var classes = {
	headerUp: 'is-up',
	headerDown: 'is-down',
	headerSticky: 'is-sticky',
	headerNavOpen: 'is-open',

	allowAnimations: 'allow-animations',
};

var States = {
	INITIAL: 'initial',
	OPENED: 'opened',
	CLOSED: 'closed'
};

var $nav;
var $search;
var $searchToggle;
var $langToggle;
var $lang;

var module = {
	init: function() {
		module._initElements();
		if ($nav.length) {
			module._initSubscriptions();
			module._initEvents();
			module._initMediaQueries();
		}

		HeaderSticky.init(selectors, classes);
	},

	_initElements: function() {
		$nav = $(selectors.nav);
		$searchToggle = $('.js-header__search-toggle');
		$search = $('.js-header__search');
		$langToggle = $('.js-header__lang-toggle');
		$lang = $('.js-header__lang');
	},

	_initSubscriptions: function () {
		subscribe('/nav/navToggle', module._closeSearch);
		subscribe('/nav/langToggle', module._closeSearch);
		subscribe('/nav/navToggle', module._closeLang);
		subscribe('/nav/searchToggle', module._closeLang);
	},

	_initEvents: function() {
		$searchToggle.on('click', module._toggleSearch);
		$langToggle.on('click', module._toggleLang);

		Keybinding.bind('escape', module._closeSearch, true);
	},

	// publish search toggle event
	// show/hide site search dependant on current state
	// toggle class on search button
	_toggleSearch: function (event) {
		event.preventDefault();

		var state = module._getSearchState();

		publish('/nav/searchToggle');
		if (state === States.OPENED) {
			module._closeSearch();
		} else {
			module._openSearch();
		}
	},

	_getSearchState: function () {
		var expanded = $search.attr('aria-expanded');
		var state;

		if (expanded === 'true') {
			state = States.OPENED;
		} else if (expanded === 'false') {
			state = States.CLOSED;
		} else {
			state = States.INITIAL;
		}

		return state;
	},

	_openSearch: function () {
		$search.attr('aria-expanded', true);
		$searchToggle.attr('aria-expanded', true);

		Velocity($search[0], 'slideDown', { duration: 250 });
		setTimeout(function () {
			$search.find('.js-header__search-input').focus();
		}, 300);
	},
	_closeSearch: function () {
		$search.attr('aria-expanded', false);
		$searchToggle.attr('aria-expanded', false);

		Velocity($search[0], 'slideUp', { duration: 250 });
	},

	// focus on the search element
	_focusSearch: function (event) {
		$search.find('.js-header__search-input').focus();
	},

	// publish language toggle event
	// show/hide site search dependant on current state
	// toggle class on language button
	_toggleLang: function (event) {
		event.preventDefault();
		publish('/nav/langToggle');
		if ($lang.attr('aria-expanded') === 'true') {
			$lang.attr('aria-expanded', false);
		} else {
			$lang.attr('aria-expanded', true);
		}
		if ($langToggle.attr('aria-expanded') === 'true') {
			$lang.attr('aria-exapnded', false);
		} else {
			$lang.attr('aria-expanded', true);
		}
	},

	// close language picker if open
	_closeLang: function () {
		if ($langToggle.attr('aria-expanded') === 'true') {
			$langToggle.trigger('click');
		}
	},

	_initMediaQueries: function() {

		MediaQueries.register([
			{
				// Bind Small Nav
				queries: MediaQueries.queries['megamenu--small'],
				shouldDegrade: false,
				match: function() {
					NavSmall.init($nav, selectors, classes);
				},
				unmatch: function() {
					NavSmall.unbind($nav, selectors, classes);
				}
			}, {
				// Bind Large Nav
				queries: MediaQueries.queries['megamenu--large'],
				shouldDegrade: true,
				match: function() {
					NavLarge.init($nav, selectors, classes);
				},
				unmatch: function() {
					NavLarge.unbind($nav, selectors, classes);
				}
			}
		]);
	}
};

var NavLoad = {
	init: module.init
};

export { NavLoad };
