/* app/ui/track */

import { Universal as Analytics } from 'App/track/analytics/universal';
import { Track as Component } from 'App/track/component';
import { Track as External } from 'App/track/external';
import { Track as Preload } from 'App/track/preload';

var TrackLoad = {
	init: function () {
		// Initiate Analytics
		Analytics.init();
		//AnalyticsClassic.init();

		// Initiate Global Page Tracking
		Preload.init();
		External.init();

		// Initiate Component Tracking
		Component.init();

		if (document.querySelector('.js-track__umbraco-form')) {
			import(/* webpackChunkName: "TrackUmbracoForm" */ 'App/track/umbraco-form').then(({ Track: UmbracoForm }) => {
				UmbracoForm.init();
			});
		}
	}
};

export { TrackLoad };
