import $ from 'jquery';
import { activate } from 'Util/activate';

var selectors = {
	yes: '.js-confirm-yes',
	no: '.js-confirm-no',
	close: '.js-confirm-close'
};

var dataSelectors = {
	yes: 'confirm-yes',
	no: 'confirm-no',
	close: 'confirm-close'
};

var Confirm = {
	check: function ($el, yes, no) {
		// Shows an element, and binds callbacks to "yes" and "no" buttons

		if (!$el.jquery) {
			$el = $($el);
		}

		$el.show();
		Confirm._bindEvents($el, yes, no);
	},

	_bindEvents: function ($el, yes, no) {
		var close = Confirm._buttonClick($.noop, $el);
		yes = Confirm._buttonClick(yes, $el);
		no = Confirm._buttonClick(no, $el);

		Confirm._unbindEvents($el);

		$el.on(activate.event, selectors.yes, yes);
		$el.on(activate.event, selectors.no, no);
		$el.on(activate.event, selectors.close, close);

		$el.data(dataSelectors.yes, yes);
		$el.data(dataSelectors.no, no);
		$el.data(dataSelectors.close, close);
	},

	_unbindEvents: function ($el) {
		var yes = $el.data(dataSelectors.yes);
		var no = $el.data(dataSelectors.no);
		var close = $el.data(dataSelectors.close);

		if (yes) {
			$el.off('click keydown', selectors.yes, yes);
			$el.data(dataSelectors.yes, null);
		}
		if (no) {
			$el.off('click keydown', selectors.no, no);
			$el.data(dataSelectors.no, null);
		}
		if (close) {
			$el.off('click keydown', selectors.close, close);
			$el.data(dataSelectors.close, null);
		}
	},

	_buttonClick: function (callback, $el) {
		return activate(function (e) {
			e.preventDefault();

			$el.hide();
			Confirm._unbindEvents($el);

			callback.apply(this, arguments);
		});
	}
};

export { Confirm };
