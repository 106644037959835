import $ from 'jquery';
import { subscribe } from 'Util/pubsub';

var selectors = {
	gallery: '.js-video-gallery',
	screen: '.js-video-gallery__screen',
	embed: '.js-video-gallery__screen iframe',

	thumbnail: '.js-video-gallery__thumbnail',
	thumbnailImage: '.js-video-gallery__thumbnail-image[src], .js-video-gallery__thumbnail-image > [src]',

	cover: '.js-video-gallery__cover',
	coverImage: '.js-video-gallery__cover-image[src], .js-video-gallery__cover-image > [src]'
};

var dataSelectors = {
	embed: 'video-gallery-embed'
};

var module = {
	init: function () {
		module._initEvents();
		module._initSubscriptions();
	},

	_initEvents: function () {
		$(selectors.thumbnail).on('click', module._changeVideo);
	},

	_initSubscriptions: function () {
		subscribe('/modal/closed', module._handleModalClose);
	},

	_changeVideo: function (e) {
		e.preventDefault();

		var $thumbnail = $(e.target).closest(selectors.thumbnail);
		var $thumbnailImage;

		var $gallery = $thumbnail.closest(selectors.gallery);

		var $screen = $gallery.find(selectors.screen);
		var $embed = $gallery.find(selectors.embed);
		var $thumbnails = $gallery.find(selectors.thumbnail);

		var $cover;
		var $coverImage;

		var embedUrl = $thumbnail.data(dataSelectors.embed);

		if ($embed.length) {
			if (module._getVideoId($embed.attr('src')) !== module._getVideoId(embedUrl)) {
				$embed.attr('src', embedUrl);
			}
		} else {
			$cover = $gallery.find(selectors.cover);
			$coverImage = $gallery.find(selectors.coverImage);

			$thumbnailImage = $thumbnail.find(selectors.thumbnailImage);
			thumbnailImageSrc = $thumbnailImage.attr('src');

			$cover.attr('href', embedUrl);
			$coverImage.attr('src', thumbnailImageSrc);

			$(window).trigger('/lazyload/resize');
		}

		$thumbnails.not($thumbnail).attr('aria-current', false);
		$thumbnail.attr('aria-current', true);
	},

	_handleModalClose: function (modal) {
		var $modal = $(modal);
		var $gallery = $modal.find(selectors.gallery);

		if ($gallery.length) {
			$gallery.each(module._handleModalCloseGallery);
		}
	},

	_handleModalCloseGallery: function (i, el) {
		var $gallery = $(el);

		var $thumbnails = $gallery.find(selectors.thumbnail);

		$thumbnails.attr('aria-current', false);
		$thumbnails.first().attr('aria-current', true);
	},

	_getVideoId: function (embedUrl) {
		var pattern = /\/embed\/(\w+)/;
		var match = embedUrl.match(pattern);
		var id = undefined;

		if (match) {
			id = match[1];
		}

		return id;
	}
};

var videoGallery = {
	init: module.init
};

export { videoGallery };
