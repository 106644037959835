/* app/ui/form/delegate */

import $ from 'jquery';
import * as Util from 'Util/core';

var selectors = {
	primary: '.js-form-delegate-primary',

	secondary: '.js-form-delegate-secondary',
	secondaryInput: '.js-form-delegate-secondary-input',

	valueInput: '.js-form-delegate-value-input'
};

var dataSelectors = {
	set: 'form-delegate-set',
	value: 'form-delegate-value'
};

var FormDelegate = {
	init: function () {
		FormDelegate._initEvents();

		$(selectors.primary).trigger('init');
		FormDelegate._initValue($(selectors.secondaryInput).filter(':visible'));
	},

	_initEvents: function () {
		$(document).on('change init', selectors.primary, FormDelegate._primaryUpdateEvent);
		$(document).on('change', selectors.secondaryInput, FormDelegate._secondaryChangeEvent);
	},

	_primaryUpdateEvent: function (e) {
		var $primary = $(e.target).closest(selectors.primary);
		var set = $primary.data(dataSelectors.set);
		var value = $primary.val();

		var $secondarySet = $(selectors.secondary).filter('[data-' + dataSelectors.set + '="' + set + '"]');
		var $target = $secondarySet.filter(function (i, el) {
			var $el = $(el);
			return $el.data(dataSelectors.value).toString() === value;
		});
		var $targetInput = $target.find(selectors.secondaryInput);

		$secondarySet.hide();
		$target.show();

		$targetInput.val('').trigger('change');
	},

	_secondaryChangeEvent: function (e) {
		var $secondaryInput = $(e.target);
		var value = $secondaryInput.val();

		var $secondary = $secondaryInput.closest(selectors.secondary);
		var set = $secondary.data(dataSelectors.set);

		var $valueInput = $(selectors.valueInput).filter('[data-' + dataSelectors.set + '="' + set + '"]');

		$valueInput.val(value).trigger('change');
	},

	_initValue: function ($input) {
		var $secondary = $input.closest(selectors.secondary);
		var set = $secondary.data(dataSelectors.set);

		var $valueInput = $(selectors.valueInput).filter('[data-' + dataSelectors.set + '="' + set + '"]');
		var name = $valueInput.attr('name');

		var queryString = Util.getQueryStringObject();
		var value = queryString[name];

		$input.val(value).trigger('change');

	}
};

export { FormDelegate };
