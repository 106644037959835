/* Scroll Appear v2.0.0 */

import { throttle } from 'Util/throttle';
import { debounce } from 'Util/debounce';

const passiveSupported = (() => {
	let passiveSupported = false;
	try {
		// https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#safely_detecting_option_support
		var options = {};

		// The getter is called when the browser tries to access the 'passive' property
		Object.defineProperty(options, 'passive', {
			get: function () {
				passiveSupported = true;
				return false;
			}
		});

		window.addEventListener('test', null, options);
		window.removeEventListener('test', null, options);
	} catch (error) {
		passiveSupported = false;
	}

	return passiveSupported;
})();

const selectors = Object.freeze({
	container: '.js-scroll-appear__container',
	appear: '.js-scroll-appear:not([data-scroll-appear="true"])'
});

const dataAttributes = Object.freeze({
	queueId: 'data-scroll-appear-queue',

	appear: 'data-scroll-appear',
	appearDelay: 'data-scroll-appear-delay',

	threshold: 'data-scroll-appear-threshold'
});

const delay = 100; // ms
const threshold = 100; // px

const Queues = Object.freeze({
	// Global queue for objects not in a container
	GLOBAL: 'global'
});

/*
[
	{
		id: string,
		queue: [
			{
				$element: Element,
				delay: int?
			}
		],
		timeout: int?
	}
]
*/
const queues = [];

const module = {
	init: function () {
		module._addQueue(Queues.GLOBAL);

		module._initEvents();

		// Try to appear all elements at the start
		module._appearElementsInViewport();
	},

	_initEvents: function () {
		const passiveOptions = passiveSupported ? { passive: true } : false;

		window.addEventListener('scroll', throttle(module._onScroll, delay), passiveOptions);
		window.addEventListener('scroll', debounce(module._onScroll, delay), passiveOptions);

		document.addEventListener('focus', module._onFocus, { capture: true });
	},

	_onScroll: function (e) {
		const $elements = document.querySelectorAll(selectors.appear);

		if ($elements) {
			module._appearElementsInViewport($elements);
		}
	},

	_appearElementsInViewport: function ($elements) {
		// Check all eligible elements by default
		if (!$elements) {
			$elements = document.querySelectorAll(selectors.appear);
			if (!$elements) {
				return;
			}
		}

		for (const $element of $elements) {
			module._appearElementInViewport($element);
		}
	},

	_appearElementInViewport: function ($element) {
		const isQueued = module._isQueued($element);
		if (isQueued) {
			// $element is queued to appear, so don't check it again
			return;
		}

		const elThreshold = module._getElementThreshold($element);
		const isInViewport = module._isInViewport($element, elThreshold);
		if (isInViewport) {
			module._addToQueue($element);
		}
	},

	_onFocus: function (e) {
		const $focus = document.activeElement;
		const $appear = $focus.closest(selectors.appear);

		if ($appear) {
			module._addToQueue($appear, true);
		}
	},

	_isQueued: function ($element) {
		// Check all queues to see if $element is queued to appear
		let isQueued = false;

		for (const queue of queues) {
			for (const item of queue.items) {
				if (item.$element === $element) {
					isQueued = true;
					break;
				}
			}

			if (isQueued) {
				break;
			}
		}

		return isQueued;
	},

	_getQueueById: function (id) {
		// Find a matching queue if one exists

		let queue = queues.filter(function (queue) {
			return queue.id === id;
		});

		if (queue.length) {
			queue = queue[0];
		} else {
			queue = null;
		}

		return queue;
	},

	_getQueueByElement: function ($element) {
		// If $element is in a container, get its queue if
		// it exists, otherwise create a new one and add it.
		// If $element is not in a container, get the global queue

		let queue;

		const $container = $element.closest(selectors.container);
		if ($container) {
			// If the item is in a container, use the container's queue
			let queueId = $container.getAttribute(dataAttributes.queueId);

			if (queueId) {
				// If the container already has an ID, get its queue
				queue = module._getQueueById(queueId);

				if (!queue) {
					// If the container's queue doesn't exist yet, create it
					queue = module._addQueue(queueId);
				}
			} else {
				// If the container has no ID, create a new queue with a new unique ID
				queueId = module._generateUniqueQueueId();
				queue = module._addQueue(queueId);
				$container.setAttribute(dataAttributes.queueId, queueId);
			}
		} else {
			// If the item is not in a container, use the global queue
			queue = module._getQueueById(Queues.GLOBAL);
		}

		return queue;
	},

	_addQueue: function (id) {
		const queue = {
			id: id,
			items: [],
			timeout: null
		};

		queues.push(queue);

		return queue;
	},

	_getElementThreshold: function ($element) {
		const elThreshold = parseInt($element.getAttribute(dataAttributes.threshold), 10) || threshold;

		return elThreshold;
	},

	_generateUniqueQueueId: function () {
		let id = Math.random().toString();
		// Remove '0.' from start, and limit to 5 characters
		id = id.substr(2, 5);

		const matchingId = queues.filter(function (queue) {
			return queue.id === id;
		});
		if (matchingId.length) {
			// Regenerate until unique
			id = module._generateUniqueQueueId.apply(this, arguments);
		}

		return id;
	},

	_addToQueue: function ($element, immediate) {
		const queue = module._getQueueByElement($element);
		const itemInQueue = queue.items.find((item) => item.$element === $element);

		if (itemInQueue) {
			// This element is already in the queue
			if (immediate) {
				// Remove it from the queue, it will be added back to the front
				const itemInQueueIndex = queue.items.indexOf(itemInQueue);
				queue.items.splice(itemInQueueIndex, 1);
			} else {
				// Leave it where it is
				return;
			}
		}

		const queueItem = {
			$element: $element,
			delay: delay
		};

		if (immediate) {
			// If an item is waiting currently, cancel its timeout and inherit
			// the prior item's delay if it's longer than the current item's delay
			if (queue.timeout) {
				window.clearTimeout(queue.timeout);
				queue.timeout = null;

				if (queue.items.length && queue.items[0].priorDelay) {
					const delay = parseInt($element.getAttribute(dataAttributes.appearDelay), 10);

					queueItem.delay = Math.max(queueItem.delay, queue.items[0].priorDelay);
				} else {
					// This item was the only item in the queue, so leave its delay alone
				}
			}

			// Add this item to the front of the queue
			queue.items.unshift(queueItem);
		} else {
			// Add this item to the end of the queue
			queue.items.push(queueItem);
		}

		if (queue.timeout === null) {
			// Use a small asynchronous delay so items entering the viewport
			// simultaneously will see one another sitting in the queue
			queue.timeout = window.setTimeout(function () {
				module._popQueue(queue.id);
			}, 10);
		}
	},

	_popQueue: function (id) {
		const queue = module._getQueueById(id);
		if (!queue) {
			queue = module._getQueueById('global');
		}

		if (!queue.items.length) {
			// console.error('Cannot pop queue with no items', id);
			return;
		}

		// Look at the first item in the queue
		const queueItem = queue.items.shift();
		queueItem.$element.setAttribute(dataAttributes.appear, true);

		if (queue.items.length) {
			const nextItem = queue.items[0];
			const elThreshold = module._getElementThreshold(nextItem.$element);

			if (module._isInViewport(nextItem.$element, elThreshold)) {
				// If the next item in the queue is still in the
				// viewport, prepare to show it after its delay
				queue.timeout = window.setTimeout(function () {
					module._popQueue(queue.id);
				}, queueItem.delay);

				// Record the prior delay
				queue.items[0].priorDelay = queueItem.delay;
			} else {
				// If the next item in the queue is no longer in
				// the viewport, show it immediately
				module._popQueue(queue.id);
			}
		} else {
			// No more items to show
			queue.timeout = null;
		}
	},

	_isInViewport: function ($element, threshold) {
		// Pretend the viewport is smaller on each edge by threshold px
		threshold = threshold || 0;

		const windowHeight = window.innerHeight;
		const maxThreshold = (windowHeight / 2) - 50;
		if (threshold > maxThreshold) {
			threshold = maxThreshold;
		}

		const coords = $element.getBoundingClientRect();

		const viewportHeight = window.innerHeight || document.documentElement.clientWidth;

		// If an element is partially in the viewport:
		// Top of page above bottom of element
		const bottom = coords.bottom >= threshold;

		// Bottom of page below top of element
		const top = coords.top <= viewportHeight - threshold;

		const isInViewport = bottom && top;

		return isInViewport;
	}
};

export { module as scrollAppear };
