/* app/ui/video/video.player */

import $ from 'jquery';
import { subscribe } from 'Util/pubsub';

var selectors = {
	player: '.js-video-player',
	cover: '.js-video-cover'
};

var dataSelectors = {
	originalMarkup: 'video-original-markup'
};

var module = {
	init: function () {
		module._initEvents();
		module._initSubscriptions();
	},

	_initEvents: function () {
		// Important to delegate the event so restoring
		// original markup later won't break the events
		$(document).on('click', selectors.player + ' ' + selectors.cover, module._loadVideo);
	},

	_initSubscriptions: function () {
		subscribe('/modal/opened', module._handleModalOpen);
		subscribe('/modal/closed', module._handleModalClose);
	},

	// Retrieve embed URL from link
	// Create iframe embed element
	// Insert iframe into player element
	// Add class to cover to control its appearance
	_loadVideo: function (event) {
		event.preventDefault();
		var $cover = $(this);
		var embedSrc = $cover.attr('href');
		var $embedElm = $('<iframe src="' + embedSrc + '?autoplay=1&rel=0" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>');
		var $player = $cover.closest(selectors.player);

		$player.prepend($embedElm);
		$cover.addClass('is-playing');
	},

	_handleModalOpen: function (modal) {
		var $modal = $(modal);
		var $player = $modal.find(selectors.player);

		if ($player.length) {
			$player.each(module._handleModalOpenPlayer);
		}
	},

	_handleModalOpenPlayer: function (i, el) {
		var $player = $(el);
		var $cover = $player.find(selectors.cover);

		var originalMarkup = $player.html();
		$player.data(dataSelectors.originalMarkup, originalMarkup);

		// Automatically load the video, to remove the need for an extra click
		$cover.trigger('click');
	},

	_handleModalClose: function (modal) {
		var $modal = $(modal);
		var $player = $modal.find(selectors.player);

		if ($player.length) {
			$player.each(module._handleModalClosePlayer);
		}
	},

	_handleModalClosePlayer: function (e, el) {
		var $player = $(el);
		var originalMarkup = $player.data(dataSelectors.originalMarkup);

		// In order to stop video from continuing to play, remove the markup
		$player.html(originalMarkup);
	}
};

var VideoPlayer = {
	init: module.init
};

export { VideoPlayer };
