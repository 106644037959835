/* app/ui/nav/sticky */

import $ from 'jquery';
import { throttle } from 'Util/throttle';
import { subscribe } from 'Util/pubsub';

var classes;

var $window;
var $body;
var $header;
var $notification;

var headerHeight;

var ignoreNextScroll = false;

var didScroll;
var lastScrollTop = $('body, html').scrollTop();
var delta = 5;

var module = {
	init: function (selectors, classList) {
		$body = $('body');
		classes = classList;

		module._initElements(selectors);
		module._initSubscriptions();
		module._initEvents();

		module._adjustForTarget(true);

		module._setScrollListener();
		module._setStickiness();
	},

	_initElements: function (selectors) {
		$window = $(window);
		$header = $(selectors.header);
		$notification = $(selectors.notification);

		headerHeight = $header.outerHeight();
	},

	_initSubscriptions: function () {
		subscribe('/window/scroll', module._handleScroll);
	},

	_initEvents: function () {
		// _adjustForTarget is minimally throttled so both events won't fire at once
		// The click event fires once, before scroll,
		// then the hashchange event fires after scroll but only
		// if the hash has changed

		$(document).on('click', 'a[href^="#"]:not([href="#"])', module._adjustForTarget);
		$(window).on('hashchange', module._adjustForTarget);
	},

	_adjustForTarget: throttle(function (e) {
		var initialLoad = e === true;

		var $target = $(':target');
		var targetScrollTop;

		if (initialLoad) {
			ignoreNextScroll = true;
		}

		if ($target.length) {
			targetScrollTop = $target.offset().top;

			if (targetScrollTop < lastScrollTop || initialLoad) {
				module._applyStickyOffset(initialLoad);

				if (!initialLoad && e.type === 'click') {
					e.preventDefault();
				}
			}
		}
	}, 10),

	_applyStickyOffset: function (initialLoad) {
		var $target = $(':target');

		var offset = $header.outerHeight();

		var targetOffset = $target.offset().top;
		var newScrollTop = targetOffset - offset;

		var shouldAdjust = true;
		var scrollTop;
		var diff;
		if (initialLoad) {
			scrollTop = $('body, html').scrollTop();
			diff = Math.abs(scrollTop - targetOffset);

			if (diff > 20) {
				// Just loaded the page, but scroll is not near the target.
				// Assume this means the page was refreshed and inherited its
				// prior scroll position.
				shouldAdjust = false;
			}
		}

		if (shouldAdjust) {
			$('html, body').scrollTop(newScrollTop);
		}
	},

	_setScrollListener: function () {
		setInterval(
			function () {
				if (didScroll) {
					module._hasScrolled();
					didScroll = false;
				}
			},
			250
		);
	},

	_handleScroll: function () {
		module._setDidScroll();
		module._setStickiness();
	},

	_setDidScroll: function () {
		didScroll = true;
	},

	_setStickiness: function () {
		var notificationHeight;
		var scrollTop;

		var isSticky = $header.hasClass(classes.headerSticky);
		var shouldBeSticky;

		notificationHeight = $notification.outerHeight() || 1;
		scrollTop = $window.scrollTop();

		shouldBeSticky = scrollTop >= notificationHeight;

		if (isSticky !== shouldBeSticky) {
			if (shouldBeSticky) {
				$header.addClass(classes.headerSticky);
			} else {
				$header.removeClass(classes.headerSticky);
			}
		}
	},

	_hideHeader: function () {
		$header.removeClass(classes.headerDown).addClass(classes.headerUp);
	},

	_hasScrolled: function () {
		if (ignoreNextScroll) {
			ignoreNextScroll = false;
			return;
		}

		var scrollTop = $window.scrollTop();

		// Make sure they scroll more than delta
		if (Math.abs(lastScrollTop - scrollTop) <= delta || $body.hasClass(classes.dropdownOpen)) {
			return false;
		}

		// If they scrolled down and are past the navbar, add class .nav-up.
		// This is necessary so you never see what is "behind" the navbar.
		if (scrollTop > lastScrollTop && scrollTop > headerHeight) {
			// Scroll Down
			$header.addClass(classes.headerUp);
			$header.removeClass(classes.headerDown);
		} else {
			// Scroll Up
			if (scrollTop + $window.height() < $(document).height()) {

				if (scrollTop > headerHeight) {
					$header.addClass(classes.headerDown);
				} else {
					$header.removeClass(classes.headerDown);
				}

				$header.removeClass(classes.headerUp);
			}
		}

		lastScrollTop = scrollTop;
	}
};

var HeaderSticky = {
	init: module.init
};

export { HeaderSticky };
