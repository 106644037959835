import $ from 'jquery';
import { debounce } from 'Util/debounce';

var selectors = {
	select: '.js-select-link'
};

var module = {
	init: function () {
		module._initEvents();
	},

	_initEvents: function () {
		$(selectors.select).on('change', module._changeEvent);
	},

	_changeEvent: function (e) {
		var $select = $(e.target);

		module._change($select);
	},

	_change: debounce(function ($select) {
		var value = $select.val();

		document.location = value;
	}, 500)
};

var selectLink = {
	init: module.init
};

export { selectLink };
